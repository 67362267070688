@import 'https://code.highcharts.com/css/highcharts.css';

.highcharts-title {
  fill: #333333;
  font-weight: bold;
  font-size: 1.7em;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.highcharts-subtitle {
  fill: #666666;
  font-size: 1em;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 2px;
}

/* Link the series colors to axis colors */
/* .highcharts-color-0 {
  fill: #7cb5ec;
  stroke: #7cb5ec;
}

.highcharts-axis.highcharts-color-0 .highcharts-axis-line {
  stroke: #7cb5ec;
}

.highcharts-axis.highcharts-color-0 text {
  fill: #7cb5ec;
}



.highcharts-axis.highcharts-color-1 .highcharts-axis-line {
  stroke: rgb(52, 151, 74);
}

.highcharts-axis.highcharts-color-1 text {
  fill: rgb(52, 151, 74);
} */

.highcharts-color-0 {
  fill: #ff6f05;
  stroke: #ff6f05;
}
.highcharts-color-2 {
  fill: #819e43;
  stroke: #819e43;
}

.highcharts-axis.highcharts-color-0 .highcharts-axis-line {
  stroke: #ff6f05;
}

.highcharts-axis.highcharts-color-0 text {
  fill: #ff6f05;
}

.highcharts-color-1 {
  fill: #819e43;
  stroke: #819e43;
}

.highcharts-axis.highcharts-color-1 .highcharts-axis-line {
  stroke: #819e43;
}

.highcharts-axis.highcharts-color-1 text {
  fill: #819e43;
}

.spline-color-1 {
  fill: #ff6f05;
  stroke: #ff6f05;
}

.spline-color-2 {
  fill: #819e43;
  stroke: #819e43;
}

.highcharts-credits {
  display: none;
}

.highcharts-axis-title {
  fill: #333333;
  font-weight: bold;
  font-size: 1em;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

path.highcharts-color-3 {
  fill: black;
  stroke: black;
}

path.highcharts-color-2 {
  fill: rgb(183, 136, 7);
  stroke: rgb(183, 136, 7);
}
