@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 1px;
  display: block;
}
::-webkit-scrollbar:hover {
  display: block;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: block;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: green;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3cf816;
}

.act-btn {
  background: #e90119;
  /* background: #2880ce; */
  display: block;
  line-height: 50px;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  text-decoration: none;
  transition: ease all 0.3s;
  position: fixed;
  right: 10px;
  bottom: 50px;
}
.bck-btn {
  background: #e90119;
  /* background: #2880ce; */
  display: block;
  line-height: 50px;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  text-decoration: none;
  transition: ease all 0.3s;
  position: fixed;
  right: 10px;
}

.chartBox {
  width: 100%;
  height: 850px;
}
